const resource = {
    en: {
        heading: 'Confirm Deletion',
        text: 'Please confirm that you want to delete this entity.',
        confirm: 'Confirm',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Löschen Bestätigen',
        text: 'Bitte bestätigen Sie, dass die ausgewählten Daten gelöscht werden sollen.',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen'
    }
}

export default resource;