<template>
    <div class="dashboard-main">
        <div>
            <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />
            <p class="basic-form-text" v-html="resource[lang].text" />
            <Button stretch color="primary" :label="resource[lang].delete_account" @click="showDeleteModal" />
        </div>
        
        <DeleteModal 
            v-if="showDeleteModalFlag"
            @confirm="deleteAccount"
            @cancel="cancelDeleteAccount"
        />

    </div>
</template>

<script>
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/delete-account-resource'

import Button from '@/components/basic/Button'
import DeleteModal from '@/components/modals/DeleteModal'

import { promiseDeleteRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        DeleteModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            showDeleteModalFlag: false,

            resource
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Requests
        deleteAccountRequest() {
            return promiseDeleteRequest('/delete-me');
        },

        // Actions
        showDeleteModal() {
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true)
        },
        cancelDeleteAccount() {
            this.showDeleteModalFlag = false;
        },
        async deleteAccount() {
            this.$store.dispatch('loading', true)
            await this.deleteAccountRequest();
            this.$store.dispatch('loading', false)

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].success, 
                color: 'positive'
            });

            this.$store.dispatch('user', null);
            localStorage.removeItem('jwt_token');
            localStorage.removeItem('token_expires');
            this.$router.push('/');
        },
    },
}
</script>

<style lang="scss" scoped>

button {
    margin-top: 2rem;
}

.dashboard-main {
    max-width: 35rem;
}

</style>