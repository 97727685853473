const resource = {
    en: {
        heading: 'Delete Account',
        text: 'If you delete your account all your data will be removed from our servers and you will not be able to use Planungsmatrix with the account or access any of the accounts data again.',
        delete_account: 'Delete Account',
        success: 'Your Account was deleted successfully',
    },
    de: {
        heading: 'Profil Löschen',
        text: 'Wenn Sie sich dazu entscheiden, Ihr Profil zu löschen, werden all die Daten, die mit dem Account in Verbinung stehen von unseren Servern gelöscht. Sie sind nicht mehr in der Lage die Planungsmatrix mit dem Profil zu verwenden oder Daten Ihres Profils wiederherzustellen.',
        delete_account: 'Profil Löschen',
        success: 'Ihr Profil wurde erfolgreich gelöscht',
    }
}

export default resource;